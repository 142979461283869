@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap');

.new-volumes-available {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  border-radius: 0 0 0 3px;
}

.kanna {
  position: fixed;
  bottom: -7px;
  left: 10px;
  z-index: 500;
  transform: scaleX(-1);
}

img[alt] {
  font-size: 10px;
}

img[alt]:after {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  font-size: 16px;
  content: '(×_×)';
}

td {
  border-bottom: none !important;
}

/* Prevent Safari from rounding out corners */
input[type='search'] {
  -webkit-appearance: none;
  border-radius: 4px;
}

